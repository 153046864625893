import styled from 'styled-components';
import ArrowOdd from '../arrow-odd.png';
import ArrowEven from '../arrow-even.png';

export const WorkflowSection = styled.div`
  font-family: oswald;
  background: #00736017;
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
  position: relative;
  padding: 100px 0;
  @media screen and (min-width: 320px) {
    padding: 6em 0;
  }
`;
export const InnerContainer = styled.div`
  @media screen and (min-width: 320px) {
    margin: 2em 0 0;
  }
`;
export const WorkflowInner = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 290px;
  @media screen and (min-width: 320px) {
    flex-direction: column;
    margin: 0;
  }
  @media screen and (min-width: 425px) {
    flex-direction: column;
    margin: 0;
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    margin: 0;
  }
`;
export const WorkflowCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  ::before {
    position: absolute;
    content: "";
    top: 0;
    left: 100px;
    background-repeat: no-repeat;
    background-position: center center;
    width: 300px;
    height: 100px;
    @media screen and (min-width: 320px) {
      display: none;
    }
  }
  :nth-of-type(2n-1)::before {
    background-image: url(${ArrowOdd});
  }
  :nth-of-type(2n)::before {
    background-image: url(${ArrowEven});
    top: 17px;
  }
  :last-child::before {
    display: none;
  }
`;
export const IconBox = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 40%;
  background-color: white;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 700;
  justify-content: center;
  color: black;
  box-shadow: 0px 14px 20px rgba(76, 119, 171, 0.1);
`;
export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -5px;
`;
export const Heading = styled.h4`
  font-size: 22px;
  color: black;
  line-height: 1.55;
  font-weight: 500;
  margin-bottom: 15px;
  @media screen and (min-width: 320px) {
    font-size: 2em;
    line-height: 1.1em;
  }
`;
export const Desc = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 1.73;
  color: #343d48;
  @media screen and (min-width: 320px) {
    font-size: 1.5em;
    line-height: 1.5em;
  }
`;
export const FlowHeaderRpm = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 320px) {
    padding: 2em 1em;
  }
`;
export const FlowHeadingRpm = styled.h1`
  font-family: Oswald;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 53px;
  color: #171717;
`;
