import styled from 'styled-components';
import ArrowOdd from '../../rmt-section/arrow-even.png';

import ArrowEven from '../../rmt-section/arrow-even.png';

export const FlowCont = styled.div`
  font-family: oswald;
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
  background: #00736017;

  display: flex;
  align-items: center;
  @media screen and (min-width: 1440px) {
  }
`;
export const FlowInnerCont = styled.div`
  margin: 50px 0;
  @media screen and (min-width: 320px) {
    margin: 10em 0;
  }
  @media screen and (min-width: 425px) {
    margin: 6em 0;
  }
`;
export const IconCont = styled.span`
  margin: 20px 0;
  @media screen and (min-width: 1440px) {
    width: 150px;
  }
`;
export const Icon1 = styled.img`
  width: 100px;
  height: 100px;

  @media screen and (min-width: 1440px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const FlowWrapper = styled.div`
  padding: 0 310px;
  display: flex;
  @media screen and (min-width: 320px) {
    flex-direction: column;
    padding: 0;
  }
  @media screen and (min-width: 425px) {
    flex-direction: column;
    padding: 0;
  }
  @media screen and (min-width: 728px) {
    padding: 0 2em;
  }
  @media screen and (min-width: 1024px) {
    padding: 0 2em;
    flex-direction: row;
  }
  @media screen and (min-width: 1440px) {
    flex-direction: row;
    padding: 0 15em;
  }
`;
export const ImgContainer = styled.div``;
export const FlowImg = styled.img`
  width: 100px;
  height: 100px;
`;
export const TextContainer = styled.div``;
export const Desc1 = styled.p``;
export const Desc2 = styled.p``;

export const MtmflowCard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 20px;
  /* border-left: 2px solid #1d9682;
  border-bottom: 2px solid #1d9682; */
  position: relative;
  /* ::before {
    position: absolute;
    content: "";
    top: 0;
    left: 100px;
    background-repeat: no-repeat;
    background-position: center center;
    width: 300px;
    height: 100px;
  }
  :nth-of-type(2n-1)::before {
    background-image: url(${ArrowOdd});
  }
  :nth-of-type(2n)::before {
    background-image: url(${ArrowEven});
    top: 17px;
  }
  :last-child::before {
    display: none;
  } */
  @media screen and (min-width: 425px) {
    padding: 1em;
    margin: 0;
  }
`;
export const MtmIconBox = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 40%;
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  justify-content: center;
  color: black;
  border: 2px solid #1d9683;
`;
export const MtmWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -5px;
`;
export const MtmText = styled.h4`
  font-size: 20px;
  color: black;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 15px;
  @media screen and (min-width: 1440px) {
    font-size: 1.6em;
    line-height: 1.4em;
  }
`;
export const Desc = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 1.73;
  color: #343d48;
`;
export const Hr = styled.hr`
  background-color: black;
  border: none;
  height: 1px;
  width: 80%;
  @media screen and (min-width: 425px) {
    width: 40%;
  }
  @media screen and (min-width: 768px) {
    width: 30%;
  }
  @media screen and (min-width: 1024px) {
    width: 15%;
  }
  @media screen and (min-width: 1440px) {
    width: 15%;
  }
`;
